import React, { useState } from 'react';

import Parameter from 'views/logic/parameters/Parameter';

import MissingParametersForWidgetPreview from './MissingParametersForWidgetPreview';
import ReportingWidget from './ReportingWidget';

type Props = {
  widget: {
    parameters: Array<Parameter>,
  },
};

const ReportingWidgetPreview = (props: Props) => {
  const { widget } = props;
  const [parameterValues, setParameterValues] = useState({});
  const requiredParameters = widget.parameters
    .filter((p) => (!p.optional && !p.defaultValue))
    .filter((p) => !parameterValues[p.name]);

  if (requiredParameters && requiredParameters.length > 0) {
    return <MissingParametersForWidgetPreview parameters={widget.parameters} parameterValues={parameterValues} onSubmit={setParameterValues} />;
  }

  return <ReportingWidget {...props} parameterValues={parameterValues} />;
};

ReportingWidgetPreview.propTypes = {};

export default ReportingWidgetPreview;
