import * as React from 'react';

import Parameter from 'views/logic/parameters/Parameter';
import usePluginEntities from 'views/logic/usePluginEntities';
import { ValidationStateEntry } from 'enterprise/parameters/components/ParameterDeclarationForm';

export type ParameterDeclarationComponentProps<T = Parameter> = {
  idx: number,
  validationState: ValidationStateEntry,
  parameter: T,
  onChange: (name: string, value: any) => void,
  onValidate: (idx: number, state: ValidationStateEntry) => void,
};

export type ParameterInputComponentProps<T = Parameter> = {
  parameter: T,
  value: any,
  onBlur?: () => void,
  onChange: (name: string, value: any, submit?: boolean) => void,
};

export interface ParameterType {
  type: string;
  title: string;
  component: React.ComponentType<ParameterInputComponentProps>,
  editComponent: React.ComponentType<ParameterDeclarationComponentProps>,
}

export const useParameterTypes = () => usePluginEntities('parameterDataTypes');

export const useParameterType = (parameterType: string) => {
  const _parameterType = (parameterType === 'any' ? 'value-parameter-v1' : parameterType) ?? 'value-parameter-v1';

  return useParameterTypes().find(({ type }) => (type === _parameterType));
};
