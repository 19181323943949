import * as React from 'react';
import { useCallback, useMemo } from 'react';
import styled from 'styled-components';

import Select from 'components/common/Select';
import Spinner from 'components/common/Spinner';
import IconButton from 'components/common/IconButton';
import OptionParameter from 'enterprise/parameters/components/option/OptionParameter';
import { useStore } from 'stores/connect';
import { ViewStore } from 'views/stores/ViewStore';
import useValuesOf from 'enterprise/parameters/components/option/useValuesOf';

import { ParameterInputComponentProps } from '../ParameterTypes';

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const SelectContainerDiv = styled.div`
  min-width: 200px;
`;

// TODO: Properly type this after introducing @types/react-select
type ContainerProps = {
  children: React.ReactNode,
  innerRef: any,
  innerProps: any,
};
const SelectContainer = ({ children, innerRef, innerProps }: ContainerProps) => <SelectContainerDiv ref={innerRef} {...innerProps}>{children}</SelectContainerDiv>;

const OptionParameterInput = ({ parameter, onChange, value }: ParameterInputComponentProps<OptionParameter>) => {
  const searchId = useStore(ViewStore, ({ view }) => view?.search?.id);
  const { data: fieldValues, isFetching, isLoading, isError, error, refetch } = useValuesOf(parameter, searchId);

  const options = useMemo(() => (fieldValues ? fieldValues.map((v) => ({ key: v, value: v })) : undefined), [fieldValues]);

  const _onChange = useCallback((selected: string) => onChange(parameter.name, selected, true), [onChange, parameter.name]);

  if (isLoading || isFetching) {
    return <Spinner />;
  }

  const refetchIcon = <IconButton title="Refresh values" name="redo" onClick={() => refetch()} />;

  if (isError) {
    return <span>Error: {error} {refetchIcon}</span>;
  }

  return options
    ? (
      <Container>
        <Select placeholder="Select or enter value"
                inputProps={{ 'aria-label': 'Select or enter a value' }}
                components={{ SelectContainer }}
                displayKey="key"
                valueKey="value"
                inputId="parameter-value-input"
                allowCreate
                onChange={_onChange}
                options={options}
                value={value} />

        {refetchIcon}
      </Container>
    )
    : <Spinner delay={200} />;
};

export default OptionParameterInput;
