import * as React from 'react';

import Parameter from 'views/logic/parameters/Parameter';
import ParameterInput from 'enterprise/parameters/components/ParameterInput';

type Props = {
  parameters: Array<Parameter>,
  parameterValues: { [key: string]: any },
  onChange: (name: string, value: any) => void,
};

const ReportParameters = ({ parameters, parameterValues, onChange }: Props) => {
  const _parameters: Array<Parameter> = Object.values(parameters);

  return (
    <dl>
      <dt>Required Parameters</dt>
      <dd>
        {_parameters.map((parameter) => (
          <ParameterInput parameter={parameter}
                          value={parameterValues[parameter.name]}
                          onChange={(name, newValue) => onChange(name, newValue)} />
        ))}
      </dd>
    </dl>
  );
};

ReportParameters.propTypes = {};

export default ReportParameters;
