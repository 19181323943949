import * as React from 'react';

import { ParameterInputComponentProps, useParameterType } from './ParameterTypes';

const TypeSpecificParameterInput = ({ parameter, onChange, onBlur, value }: ParameterInputComponentProps) => {
  const { component: Component } = useParameterType(parameter?.type);

  return <Component parameter={parameter} value={value} onChange={onChange} onBlur={onBlur} />;
};

export default TypeSpecificParameterInput;
