class CloudDocs {
  PAGES = {
    FORWARDER: 'enterprise/cloud_forwarder.html',
    INPUT_PROFILE: 'enterprise/cloud_forwarder.html#input-profile',
  }
}

const cloudDocs = new CloudDocs();

export default cloudDocs;
