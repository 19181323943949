import React from 'react';
import styled from 'styled-components';

import { LinkContainer } from 'components/graylog/router';
import { DocumentTitle, PageHeader } from 'components/common';
import { Button, ButtonToolbar, Col, Row } from 'components/graylog';
import Routes from 'routing/Routes';
import DocumentationLink from 'components/support/DocumentationLink';
import history from 'util/History';

import { InputProfilesActions } from './stores/InputProfilesStore';
import CloudDocs from './util/CloudDocs';
import withIsPermitted from './util/withIsPermitted';
import type { InputProfile } from './Types';
import InputProfileForm from './input-profiles/InputProfileForm';

const REQUIRED_PERMISSIONS = ['inputprofiles:create'];

const StyledH2 = styled.h2`
  margin-bottom: 20px;
`;

const NewInputProfilePage = () => {
  const _handleInputProfileCreation = (inputProfile: InputProfile) => {
    history.push(Routes.pluginRoute('SYSTEM_INPUT_PROFILES_INPUTPROFILEID')(inputProfile.id));
  };

  const _handleSubmit = (nextInputProfile) => {
    InputProfilesActions.create(nextInputProfile)
      .then((result) => {
        _handleInputProfileCreation(result);

        return result;
      });
  };

  const _handleCancel = () => {
    history.push(Routes.pluginRoute('SYSTEM_INPUT_PROFILES'));
  };

  return (
    <DocumentTitle title="New Input Profile">
      <PageHeader title="New Input Profile">
        <span>Use Input Profiles to create a set of Inputs that can be shared across many Forwarders.</span>
        <span>
          Graylog Input Profiles&nbsp;
          <DocumentationLink text="documentation" page={CloudDocs.PAGES.INPUT_PROFILE} />.
        </span>
        <ButtonToolbar>
          <LinkContainer to={Routes.pluginRoute('SYSTEM_FORWARDERS')}>
            <Button bsStyle="info">Forwarders</Button>
          </LinkContainer>
          <LinkContainer to={Routes.pluginRoute('SYSTEM_INPUT_PROFILES')}>
            <Button bsStyle="info">Input Profiles</Button>
          </LinkContainer>
        </ButtonToolbar>
      </PageHeader>
      <Row className="content">
        <Col md={7}>
          <StyledH2>Input Profile Details</StyledH2>
          <InputProfileForm onCancel={_handleCancel} onSubmit={_handleSubmit} buttonText="Create" />
        </Col>
      </Row>
    </DocumentTitle>
  );
};

NewInputProfilePage.propTypes = {};

export default withIsPermitted(NewInputProfilePage, REQUIRED_PERMISSIONS);
