import React, { useEffect } from 'react';

import { LinkContainer } from 'components/graylog/router';
import { DocumentTitle, PageHeader, Spinner } from 'components/common';
import { useStore } from 'stores/connect';
import { Button, ButtonToolbar } from 'components/graylog';
import Routes from 'routing/Routes';
import DocumentationLink from 'components/support/DocumentationLink';

import InputProfilesListComponent from './input-profiles/InputProfilesListComponent';
import InputProfilesStore, { InputProfilesActions } from './stores/InputProfilesStore';
import ForwardersStore, { ForwardersActions } from './stores/ForwardersStore';
import EmptyInputProfileListComponent from './input-profiles/EmptyInputProfileListComponent';
import CloudDocs from './util/CloudDocs';
import withIsPermitted from './util/withIsPermitted';

const REQUIRED_PERMISSIONS = ['forwarders:read', 'inputprofiles:read', 'forwarderinputs:read'];

const InputProfilesPage = () => {
  const { inputProfiles, pagination } = useStore(InputProfilesStore);
  const { all: forwarders } = useStore(ForwardersStore);

  useEffect(() => {
    InputProfilesActions.list({});
    ForwardersActions.listAll();
  }, []);

  const _reloadInputProfileList = () => {
    InputProfilesActions.list({
      page: pagination.page,
      pageSize: pagination.perPage,
      query: pagination.query,
    });
  };

  const _saveForwardersAssignment = (updatedForwarders) => {
    const toBeUpdatedForwarders = [];

    updatedForwarders.forEach((uf) => {
      const forwarder = forwarders.find((f) => f.id === uf.forwarderId);

      if (forwarder) {
        toBeUpdatedForwarders.push({ ...forwarder, input_profile_id: uf.inputProfileId });
      }
    });

    ForwardersActions.updates(toBeUpdatedForwarders);
  };

  const isLoading = () => {
    return !inputProfiles || !forwarders;
  };

  const handleQueryChange = (page?: number, perPage?: number, query?: string) => {
    InputProfilesActions.list({
      page,
      pageSize: perPage,
      query,
    });
  };

  const loadContent = () => {
    return inputProfiles.length === 0 && pagination.query === '' ? <EmptyInputProfileListComponent />
      : (
        <InputProfilesListComponent inputProfiles={inputProfiles}
                                    forwarders={forwarders}
                                    onForwardersAssign={_saveForwardersAssignment}
                                    onInputProfileUpdate={_reloadInputProfileList}
                                    pagination={pagination}
                                    onQueryChange={handleQueryChange} />
      );
  };

  return (
    <DocumentTitle title="Input Profiles">
      <>
        <PageHeader title="Input Profiles">
          <span>List of Input Profiles created in this Graylog instance.</span>
          <>Learn more about Graylog Input Profiles in the&nbsp;
            <DocumentationLink text="documentation" page={CloudDocs.PAGES.INPUT_PROFILE} />.
          </>
          <ButtonToolbar>
            <LinkContainer to={Routes.pluginRoute('SYSTEM_FORWARDERS')}>
              <Button bsStyle="info">Forwarders</Button>
            </LinkContainer>
            <LinkContainer to={Routes.pluginRoute('SYSTEM_INPUT_PROFILES')}>
              <Button bsStyle="info">Input Profiles</Button>
            </LinkContainer>
          </ButtonToolbar>
        </PageHeader>
        {isLoading() ? <Spinner /> : loadContent()}
      </>
    </DocumentTitle>
  );
};

export default withIsPermitted(InputProfilesPage, REQUIRED_PERMISSIONS);
